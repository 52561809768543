import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect, useHistory} from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "../views/ClientLogin/Login";
import Shows from '../views/Shows/index';
import Episodes from '../views/Episodes/index';
import * as appRoutes from "./routeConstants/appRoutes";
import TopNavBar from "../shared/components/TopNavBar";
import ListPodcasts from "../views/ListPodcasts";
import { withRouter } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";

export const appHistory = createBrowserHistory();

const isAuthenticated = (Component) => {
    const Authentication = (props) => {
        const history = useHistory();
        const authenticated = useIsAuthenticated();
        useEffect(() => {
            if (!authenticated && history.location.pathname !== appRoutes.LOGIN) {
                return history.push(appRoutes.LOGIN);
            }
        }, [props]);

        return <Component {...props} />
    }
    return withRouter(Authentication);
};

const AppRoutes = () => {
    const routes = [
        {exact: true, path: appRoutes.LOGIN, component: Login},
        {exact: true, path: appRoutes.EPISODES, component: ((Episodes))},
    ];

    return (
        <div>
            <Router history={appHistory}>
                <TopNavBar />
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            {...route}
                            component={route.component}
                        />
                    ))}
                    <Route
                        path="*"
                        render={() => <Redirect to={appRoutes.LOGIN} />}
                    />
                </Switch>
            </Router>
        </div>
    );
}

export default AppRoutes;