import React from 'react'
import './episodesList.css'
import EpisodeCard from '../EpisodesCard'

const EpisodesList = () => {
  return (
    <div className="episode-box__outer-container">
      <EpisodeCard />
    </div>
  )
}

export default EpisodesList
