import React from 'react'
import '../Shows/shows.css'
import EpisodesList from '../../components/EpisodesList/index'
import ShowDetailsSidebar from '../../components/ShowDetailsSidebar/index';

const Episodes = () => {
  return (
    <div className='shows-container'>
        <div className='flex--1'><ShowDetailsSidebar/></div>
        <div className='flex--2'><EpisodesList /></div>
    </div>
  )
}

export default Episodes
