import React from 'react'
import './showDetailsSidebar.css';
import orkinProImg from '../../assets/images/OrkinProLogo.jpg';
const ShowDetailsSidebar = ({img}) => {
  return (
    <div className='show-details__container'>
      <div className='image'>
          <img src={orkinProImg} className='image' />
      </div>
      <div className='details'>
      <div className='title'>The Orkin Pro</div>
      <div className='description'>When your days are full of squeezing into tight places and driving from service to service, staying connected can be as challenging as dealing with a full-blown infestation! Welcome to The Orkin Pro Podcast, the only show that connects Orkin Pros from across the country, offering up the latest tips, tools, insights, and innovations from the industry and sharing the real-life on-the-ground stories from our team of Pros. So join our host, Cori Skinner, as she dives into the latest hot topics, speaks with inspiring guests, and tells a few bug jokes along the way. This is one podcast you don't want to miss as you ride to the rescue for your next service! Presented by Orkin www.orkin.com</div>
      </div>
    </div>
  )
}

export default ShowDetailsSidebar
