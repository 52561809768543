import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBrowserType } from "./redux/ui/actions";
import { getBrowserType } from "./utils/utils";
import "./App.css";
import AppRoutes from './routes';
import { MsalProvider } from "@azure/msal-react";

const App = ({pca}) => {
	const dispatch = useDispatch();

	const setAvailableArea = () => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty("--vh", `${vh}px`);
	};

	useEffect(() => {
		setAvailableArea();
		window.addEventListener("resize", setAvailableArea);
		return () => window.removeEventListener("resize", setAvailableArea);
	});

	useEffect(() => {
		const browser = getBrowserType();
		dispatch(setBrowserType(browser));
	}, []);

	return (
		<div className="app--main--container">
			<MsalProvider instance={pca}>
				<AppRoutes />
			</MsalProvider>
		</div>
	);
};

export default App;
