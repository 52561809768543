import primaryLogo from "../../../assets/images/orkinLogo.png";
import React from "react";
import './topNavBar.css';
import {useHistory} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import { useMsal,useIsAuthenticated } from "@azure/msal-react";

const TopNavBar = () => {

    const history = useHistory();
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect();
        setTimeout(()=>history.push(appRoutes.LOGIN));
    }

    return(
        <div className="app__header">
            <img src={primaryLogo} alt='' className='app__header--logo'/>
            {useIsAuthenticated() &&
                <span onClick={handleLogout}
                    className='app_header__action'
                >
                    Log out
                </span>
            }
        </div>
    )
}

export default TopNavBar;